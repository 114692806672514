"use client";

import Link from "next/link";
import { usePathname, useSearchParams } from "next/navigation";

import styles from "./BreakingCard.module.scss";
import { useArticleModalStore } from "../../store/useArticleModalStore";

const BreakingCard = ({
  articleLink: { articleId, slug },
  vignette,
  title,
}: {
  articleLink: { articleId: string; slug: string };
  title: string;
  vignette: string;
}) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { openModal } = useArticleModalStore();

  return (
    <div className={styles.breakingCard}>
      <Link
        href={`/${slug}/a/${articleId}`}
        className={styles.link}
        prefetch={true}
        onClick={() =>
          openModal(
            searchParams.size > 0 ? `${pathname}?${searchParams}` : pathname,
          )
        }
      >
        <span className={styles.separator}>●</span>
        <div>
          <span className={styles.vignette}>{vignette}</span>
          <span className={styles.title}>{title}</span>
        </div>
      </Link>
    </div>
  );
};

export { BreakingCard };
