import Link from "next/link";

import { getSlugFromTitle } from "../../../../../utils/getSlugFromTitle";

interface StoryVignetteTitleProps {
  title?: string;
  id?: string;
  isCategory: boolean;
  className?: string;
  slug?: string;
}

const StoryVignetteTitle: React.FC<StoryVignetteTitleProps> = ({
  title,
  id,
  className,
  isCategory,
  slug,
}) => {
  if (!title) return null;

  const titleSlug = getSlugFromTitle(title);
  const link = isCategory ? `/${slug}` : `/t/${titleSlug}/${id}`;

  return id ? (
    <Link href={link} className={className} prefetch={false}>
      {title}
    </Link>
  ) : (
    <span className={className}>{title}</span>
  );
};

export { StoryVignetteTitle };
