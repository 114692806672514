import Image from "next/image";
import cn from "classnames";

import styles from "./InfoCard.module.scss";
import { Icon } from "../Icon/Icon";
import { InfoCardLink } from "./InfoCardLink";

interface InfoCardProps {
  articleLink: {
    articleId: string;
    slug?: string;
  };
  title: string;
  vignette: string;
  iconImageUrl?: string;
  articleIsPremium?: boolean;
  viewType: "top" | "latest";
}

const InfoCard = ({
  articleLink: { articleId, slug },
  vignette,
  title,
  iconImageUrl,
  articleIsPremium,
  viewType,
}: InfoCardProps) => {
  return (
    <div
      className={cn([
        styles.infoCard,
        viewType === "latest" && styles.needSpace,
      ])}
    >
      <InfoCardLink
        slug={slug}
        articleId={articleId}
        articleTitle={title}
        articleIsPremium={articleIsPremium}
      >
        {iconImageUrl && (
          <div className={styles.imageContainer}>
            <Image src={iconImageUrl} alt="" width={60} height={60} />
          </div>
        )}
        <div className={styles.textContainer}>
          <span>
            <span className={styles.vignette}>{vignette}</span>
            <span className={styles.separator}>●</span>
          </span>
          <span className={styles.title}>{title}</span>
        </div>
        <div className={styles.caretRightContainer}>
          <Icon variant="caretRight" className={styles.caretIcon} />
        </div>
      </InfoCardLink>
    </div>
  );
};

export type { InfoCardProps };
export { InfoCard };
