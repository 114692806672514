"use client";

import Link from "next/link";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

import type { ReactNode } from "react";

import { usePulse } from "../../pulse/usePulse";
import styles from "./InfoCard.module.scss";

const InfoCardLink = ({
  slug,
  articleId,
  articleTitle,
  articleIsPremium,
  children,
}: {
  articleId?: string;
  articleTitle?: string;
  articleIsPremium?: boolean;
  slug?: string;
  children: ReactNode;
}) => {
  const { ref, inView } = useInView({
    threshold: 2 / 3,
  });
  const { trackInfoCardImpression } = usePulse();

  useEffect(() => {
    if (inView && articleTitle && articleId) {
      trackInfoCardImpression({ articleId, articleTitle, articleIsPremium });
    }
  }, [
    inView,
    articleTitle,
    articleId,
    trackInfoCardImpression,
    articleIsPremium,
  ]);

  return (
    <Link
      href={`/${slug}/a/${articleId}`}
      className={styles.link}
      rel={"canonical"}
      ref={ref}
      prefetch={true}
    >
      {children}
    </Link>
  );
};

export { InfoCardLink };
