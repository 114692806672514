import cn from "classnames";

import type { Ad as AdType } from "../../types/ads/adResource";
import type { Group } from "../../types/content";
import type { ListView } from "../../types/webTypes";

import styles from "./Feed.module.scss";
import { StickySideAd } from "../Ad/StickySideAd/StickySideAd";
import { TopNewsLayout } from "./TopNewsLayout/TopNewsLayout";
import { LatestNewsLayout } from "./LatestNewsLayout/LatestNewsLayout";
import { ListLayout } from "./ListLayout/ListLayout";

const Feed = ({
  data,
  wideScreenSideAd,
  wideScreenSideAd2,
  sponsoredBannerAdResource,
  contentDisplayAd,
  isFirstFeed,
  layoutType,
  listView,
  endOfFeed,
  headerTitle,
}: {
  data: Group[];
  wideScreenSideAd?: AdType;
  wideScreenSideAd2?: AdType;
  sponsoredBannerAdResource?: AdType;
  contentDisplayAd?: AdType;
  isFirstFeed?: boolean;
  layoutType: "top" | "latest" | "list";
  listView?: ListView;
  endOfFeed?: boolean;
  headerTitle?: string;
}) => {
  const hasFeedData = !!data?.length;
  return (
    <div
      className={cn([
        styles.feedContainer,
        wideScreenSideAd && styles.withWidescreenAd,
        isFirstFeed && styles.firstFeed,
      ])}
    >
      <div className={cn([styles.feed, endOfFeed && styles.endOfFeed])}>
        {layoutType === "top" && (
          <TopNewsLayout
            data={data}
            isFirstFeed={isFirstFeed}
            contentDisplayAd={contentDisplayAd}
          />
        )}
        {layoutType === "latest" && (
          <LatestNewsLayout data={data} isFirstFeed={isFirstFeed} />
        )}

        {layoutType === "list" && (
          <ListLayout
            listView={listView!}
            isFirstFeed={isFirstFeed}
            data={data}
            sponsoredBannerAdResource={sponsoredBannerAdResource}
            endOfFeed={endOfFeed}
            headerTitle={headerTitle}
          />
        )}
      </div>
      {hasFeedData &&
        (isFirstFeed ? (
          <StickySideAd adResource={wideScreenSideAd} hasBottomGradientStyle />
        ) : (
          <StickySideAd
            adResource={wideScreenSideAd2}
            hasBottomGradientStyle={false}
          />
        ))}
    </div>
  );
};

export { Feed };
