import cn from "classnames";

import type { ResourceComponent } from "../Resource/types";
import type { StoryVignette as StoryVignetteType } from "../../../../types/content";

import styles from "./StoryVignette.module.scss";
import { StoryVignetteSuffix } from "./StoryVignetteSuffix/StoryVignetteSuffix";
import { StoryVignetteTitle } from "./StoryVignetteTitle/StoryVignetteTitle";
import { StoryVignetteSupertag } from "./StoryVignetteSupertag/StoryVignetteSupertag";

const StoryVignette: ResourceComponent<StoryVignetteType> = ({
  resource,
  isPremium,
  isNative,
  layoutType,
  isLicensedArticle,
}) => {
  const hasSupertag = !!resource.supertag;
  const hasTitle = !!resource.title;
  const hasSuffix = !!resource.suffix;

  return (
    <div
      className={cn([
        styles.storyVignette,
        layoutType && styles[layoutType],
        isLicensedArticle && styles.licensedArticle,
      ])}
    >
      <StoryVignetteSupertag
        supertag={resource.supertag}
        className={cn([styles.titleLink, isPremium && styles.premium])}
      />
      {hasSupertag && (hasTitle || hasSuffix) && <span> • </span>}
      <StoryVignetteTitle
        isCategory={resource.ref === "category"}
        slug={resource?.slug}
        title={resource.title}
        id={resource.id}
        className={cn([
          styles.titleLink,
          (hasSupertag || isNative) && styles.uncoloredLink,
          isPremium && styles.premium,
          layoutType && styles[layoutType],
          isLicensedArticle && styles.licensedArticle,
        ])}
      />
      {(hasSupertag || hasTitle) && hasSuffix && (
        <span
          className={cn([
            styles.suffix,
            isNative && styles.fadedText,
            layoutType && styles[layoutType],
          ])}
        >
          {` ${"•"} `}
        </span>
      )}
      <StoryVignetteSuffix
        suffix={resource.suffix}
        className={cn([
          styles.suffix,
          isNative && styles.fadedText,
          layoutType && styles[layoutType],
        ])}
      />
    </div>
  );
};

export { StoryVignette };
