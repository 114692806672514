import type { Ad as AdType } from "../../../types/ads/adResource";

import { Ad } from "../Ad";
import styles from "./FullscreenScrollAd.module.scss";

const FullScreenScroll = ({ adResource }: { adResource?: AdType }) => {
  if (!adResource || !adResource.havePlacementRule) return null;
  return (
    <div className={styles.adContainer}>
      <div className={styles.adContent}>
        <Ad
          resource={adResource}
          initialSize={{ width: "100%", height: "100vh" }}
          isFullScreenScroll
        />
      </div>
    </div>
  );
};

export { FullScreenScroll };
