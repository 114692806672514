import cn from "classnames";

import type { TeaserClusterFooterItems } from "../helpers/rawGroupToTeaserCluster";
import type { ArticleStyle } from "../../../types/content";

import styles from "./TeaserClusterFooter.module.scss";
import { Button } from "../../Button/Button";
import { getSlugFromTitle } from "../../../utils/getSlugFromTitle";

function TeaserClusterFooter({
  items,
  style,
  hasBorder,
}: {
  items: TeaserClusterFooterItems;
  style: ArticleStyle;
  hasBorder?: boolean;
}) {
  if (!Array.isArray(items) || items.length === 0) {
    return null;
  }

  return (
    <div
      className={cn([
        styles.footerContainer,
        styles[style],
        !hasBorder && styles.noBorder,
      ])}
    >
      {items.map((resource, index) => {
        if (resource.type === "ReadMoreButton") {
          const link = resource.link?.slug
            ? `/t/${resource.link.slug}/${resource.link.topic_id}`
            : `/t/${resource.link.topic_id}`;
          return (
            <Button
              key={index}
              text={`${resource.value}`}
              size="small"
              variant="secondary"
              type="link"
              href={link}
              isPremium={style === "premium"}
            />
          );
        }
        if (resource.type === "StoryVignette") {
          const slug = getSlugFromTitle(resource.title);
          return (
            <Button
              key={index}
              text={`Läs mer om ${resource.title}`}
              variant="text"
              size="tiny"
              type="link"
              isPremium={style === "premium"}
              href={`/t/${slug}/${resource.id}`}
            />
          );
        }
        return null;
      })}
    </div>
  );
}

export { TeaserClusterFooter };
