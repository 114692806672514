import cn from "classnames";

import type { Ad as AdType } from "../../../types/ads/adResource";

import { Ad } from "../Ad";
import styles from "./StickySideAd.module.scss";

const StickySideAd = ({
  adResource,
  hasBottomGradientStyle,
}: {
  adResource?: AdType;
  hasBottomGradientStyle?: boolean;
}) => {
  return (
    <div
      className={cn([
        styles.wideScreenAdAnchor,
        hasBottomGradientStyle && styles.bottomGradient,
      ])}
    >
      <div className={styles.widescreenAd}>
        {adResource && adResource.havePlacementRule && (
          <Ad resource={adResource} initialSize={{ width: 250, height: 600 }} />
        )}
      </div>
    </div>
  );
};

export { StickySideAd };
