import type { AdResource } from "../Ad";

import { Ad } from "../Ad";

const ContentDisplayAd = ({
  contentDisplayResource,
}: {
  contentDisplayResource: AdResource;
}) => {
  if (!contentDisplayResource || !contentDisplayResource.havePlacementRule) {
    return null;
  }

  return <Ad isContentDisplay resource={contentDisplayResource} />;
};

export { ContentDisplayAd };
