import React from "react";

import styles from "./DotLoader.module.scss";

const DotLoader = () => (
  <div className={styles.dotsContainer}>
    <span className={styles.dot}></span>
    <span className={styles.dot}></span>
    <span className={styles.dot}></span>
  </div>
);

export { DotLoader };
