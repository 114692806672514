import type { Box } from "../../../../types/content";
import type { TeaserClusterHeaderItems } from "../../../TeaserCluster/helpers/rawGroupToTeaserCluster";

import styles from "./LatestNewsBoxCluster.module.scss";
import { Teaser } from "../../../Teaser/Teaser";
import { TeaserCluster } from "../../../TeaserCluster/TeaserCluster";

const LatestNewsBoxCluster = ({
  boxGroup,
  teaserStyle,
  boxName,
}: {
  boxGroup: Box;
  teaserStyle: "regular" | "premium" | "native";
  boxName: string;
}) => {
  return (
    <div className={styles.latestNewsBoxContainer}>
      <TeaserCluster
        footer={boxGroup.footer}
        header={boxGroup.header as TeaserClusterHeaderItems}
        style={teaserStyle}
        hasCardStyling={false}
        hasFooterBorder={false}
      >
        {boxGroup.articles.map((article, articleIndex) => {
          if (article.type !== "Article") return null;
          return (
            <Teaser
              article={article}
              articlePositionInCluster={articleIndex + 1}
              index={articleIndex + 1}
              key={`${article.article_id} ${articleIndex}`}
              inCluster
              isBox={teaserStyle === "premium"}
              isNative={teaserStyle === "native"}
              boxName={boxName}
            />
          );
        })}
      </TeaserCluster>
    </div>
  );
};

export { LatestNewsBoxCluster };
