import type { Ad as AdType } from "../../../types/ads/adResource";

import styles from "./DesktopFeedPanoramaAd.module.scss";
import { Ad } from "../Ad";

const DesktopFeedPanoramaAd = ({ adResource }: { adResource?: AdType }) => {
  if (!adResource || !adResource.havePlacementRule) return null;
  return (
    <div className={styles.adContainer}>
      <div className={styles.adContent}>
        <Ad resource={adResource} hasDynamicSize />
      </div>
    </div>
  );
};

export { DesktopFeedPanoramaAd };
