import type {
  Article,
  Box,
  Decoration,
  Resource,
  StoryVignette,
} from "../../../types/content";

type TeaserClusterHeaderTypes = "Overline" | "Title" | "Intro";

type TeaserClusterHeaderType = {
  type: TeaserClusterHeaderTypes;
  value: string;
  decoration?: Decoration;
};

type ReadMoreButtonResource = {
  type: "ReadMoreButton";
  value?: string;
  link: {
    type: "Category" | "story";
    category_id?: string;
    topic_id?: string;
    slug?: string;
    title?: string;
  };
  decoration: Decoration;
};

type TeaserClusterHeaderItems = (TeaserClusterHeaderType | StoryVignette)[];

type TeaserClusterFooterItems = (ReadMoreButtonResource | StoryVignette)[];

// TODO: When teaserCluster objects are implemented in content-api, this should be moved to content types
type TeaserCluster = {
  type: "Box" | "TeaserCluster";
  name: string;
  header: TeaserClusterHeaderItems;
  articles: Article[];
  footer: TeaserClusterFooterItems;
};

const isArticle = (r: Article | Resource | Box): r is Article =>
  r.type === "Article";

function rawGroupToTeaserCluster(
  raw: (Article | Resource | Box)[],
): TeaserCluster | null {
  const firstResource = raw[0];

  if (firstResource.type === "Box") {
    return {
      type: "Box",
      name: firstResource.name,
      header: firstResource.header as TeaserClusterHeaderItems,
      articles: firstResource.articles,
      footer: firstResource.footer,
    };
  }

  const articles: Article[] = raw.filter((resource) =>
    isArticle(resource),
  ) as Article[];

  if (articles.length === 0) {
    return null;
  }

  const storyVignette = articles.reduce(
    (acc, article) => {
      if (article.type !== "Article" || acc !== undefined) {
        return acc;
      }

      const storyVignette = article.resources.find(
        (resource) => resource.type === "StoryVignette",
      );

      if (storyVignette && !storyVignette?.id && article?.story?.topic_id) {
        storyVignette.id = article?.story?.topic_id;
      }
      return storyVignette as StoryVignette | undefined;
    },
    undefined as undefined | StoryVignette,
  );

  const footer: TeaserClusterFooterItems = [];
  const header: TeaserClusterHeaderItems = [];

  if (storyVignette) {
    header.push(storyVignette);

    if (
      (articles[0].type === "StoryHeading" || articles[0].story_vignette) &&
      articles.length > 1
    ) {
      footer.push(storyVignette);
    }
  }

  return {
    type: "TeaserCluster",
    name: storyVignette ? storyVignette.title : "",
    header,
    articles: articles.reduce((teasers, article) => {
      if (article.type !== "Article") {
        return teasers;
      }
      teasers.push(article);
      return teasers;
    }, [] as any[]),
    footer,
  };
}

export { rawGroupToTeaserCluster };
export type {
  ReadMoreButtonResource,
  TeaserClusterHeaderType,
  TeaserClusterHeaderTypes,
  TeaserClusterHeaderItems,
  TeaserClusterFooterItems,
  TeaserCluster,
};
