import React from "react";
import cn from "classnames";
import { HeadingLevel } from "@ariakit/react";

import type {
  TeaserClusterFooterItems,
  TeaserClusterHeaderItems,
} from "./helpers/rawGroupToTeaserCluster";

import styles from "./TeaserCluster.module.scss";
import { TeaserClusterHeader } from "./TeaserClusterHeader/TeaserClusterHeader";
import { TeaserClusterFooter } from "./TeaserClusterFooter/TeaserClusterFooter";

interface TeaserClusterProps {
  children: React.ReactNode[] | React.ReactNode;
  style: "native" | "premium" | "regular";
  header: TeaserClusterHeaderItems;
  footer: TeaserClusterFooterItems;
  hasCardStyling?: boolean;
  hasFooterBorder?: boolean;
  isSmallCluster?: boolean;
  isArticlePageTeaser?: boolean;
}

function TeaserCluster({
  children,
  header,
  style,
  footer,
  hasCardStyling = true,
  hasFooterBorder = true,
  isSmallCluster = false,
  isArticlePageTeaser = false,
}: TeaserClusterProps) {
  return (
    <div
      className={cn([
        styles.clusterContainer,
        styles[style],
        !hasCardStyling && styles.noCardOutline,
        isSmallCluster && styles.smallCluster,
        isArticlePageTeaser && styles.articlePageTeaser,
      ])}
    >
      <HeadingLevel>
        <TeaserClusterHeader
          style={style}
          items={header}
          isSmallCluster={isSmallCluster}
        />
        <div className={cn([styles.articleCluster, styles[style]])}>
          {children}
        </div>
        <TeaserClusterFooter
          style={style}
          items={footer}
          hasBorder={hasFooterBorder}
        />
      </HeadingLevel>
    </div>
  );
}

export { TeaserCluster };
export type { TeaserClusterProps };
