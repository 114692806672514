"use client";

import { useCallback, useEffect, useRef, useState } from "react";

import type { Group } from "../../types/content";
import type { Ad as AdType } from "../../types/ads/adResource";
import type { ListView } from "../../types/webTypes";

import styles from "./FeedLoader.module.scss";
import { Feed } from "../Feed/Feed";
import { fetchFeedFromUrl } from "../../services/fetchFeed";
import { DotLoader } from "../DotLoader/DotLoader";
import { DesktopFeedPanoramaAd } from "../Ad/DesktopFeedPanoramaAd/DesktopFeedPanoramaAd";
import { FullScreenScroll } from "../Ad/FullscreenScrollAd/FullscreenScrollAd";
import { omitRepeated } from "./util/omitRepeated";

const FeedLoader = ({
  children,
  fetchFeedUrl,
  ads,
  feedLayoutType,
  initialItems,
  listView,
  headerTitle,
}: React.PropsWithChildren<{
  children?: React.ReactNode;
  fetchFeedUrl: string;
  ads?: {
    midFeedDesktopPanoramaAd?: AdType;
    fullscreenScrollAd?: AdType;
    wideScreenSideAd?: AdType;
    wideScreenSideAd2?: AdType;
    sponsoredBannerAdResource?: AdType;
  };
  feedLayoutType: "top" | "latest" | "list";
  listView?: ListView;
  initialItems: Group[];
  headerTitle?: string;
}>) => {
  const ref = useRef(null);
  const [feedData, setFeedData] = useState<Group[]>([]);
  const [loading, setLoading] = useState(false);
  const [fetchUrl, setFetchUrl] = useState(fetchFeedUrl);
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [noMoreArticles, setNoMoreArticles] = useState(false);

  const {
    midFeedDesktopPanoramaAd,
    fullscreenScrollAd,
    wideScreenSideAd,
    wideScreenSideAd2,
    sponsoredBannerAdResource,
  } = ads || {};

  const loadMoreFeedData = useCallback(
    async (url: string) => {
      setLoading(true);
      const fetchedFeedData = await fetchFeedFromUrl({ path: url });
      if (!fetchedFeedData?.data) {
        setLoading(false);
        return;
      }

      if (
        !fetchedFeedData.error &&
        fetchedFeedData.data.articles.length === 0 &&
        initialItems.length > 0
      ) {
        setNoMoreArticles(true);
        return;
      }
      setFetchUrl(fetchedFeedData.data.links.next || "");
      setFeedData((prevFeedData) =>
        omitRepeated(prevFeedData, fetchedFeedData.data.articles, initialItems),
      );
      setLoading(false);
    },
    [initialItems],
  );

  useEffect(() => {
    const handleIntersection = ([entry]: IntersectionObserverEntry[]) => {
      if (entry.isIntersecting && fetchUrl && !loading && !shouldFetchData) {
        setShouldFetchData(true);
      }
    };
    const observer = new IntersectionObserver(handleIntersection);

    const scrollLimitElement = ref.current;

    if (scrollLimitElement) {
      observer.observe(scrollLimitElement);
    }

    return () => {
      if (scrollLimitElement) {
        observer?.unobserve(scrollLimitElement);
      }
      observer.disconnect();
    };
  }, [loading, fetchUrl, shouldFetchData]);

  useEffect(() => {
    if (shouldFetchData && !loading && fetchUrl) {
      setShouldFetchData(false);
      loadMoreFeedData(fetchUrl);
    }
  }, [shouldFetchData, fetchUrl, loading, loadMoreFeedData]);

  if (!fetchFeedUrl) return <>{children ? children : null}</>;

  return (
    <div className={styles.feedLoaderContainer}>
      {feedLayoutType === "list" ? (
        <Feed
          data={[...initialItems, ...feedData]}
          wideScreenSideAd={wideScreenSideAd}
          sponsoredBannerAdResource={sponsoredBannerAdResource}
          layoutType={feedLayoutType}
          listView={listView}
          isFirstFeed={Array.isArray(initialItems) && initialItems.length > 0}
          endOfFeed={noMoreArticles}
          headerTitle={headerTitle || ""}
        />
      ) : (
        <>
          {children}
          <DesktopFeedPanoramaAd adResource={midFeedDesktopPanoramaAd} />
          <FullScreenScroll adResource={fullscreenScrollAd} />
          {feedData && (
            <Feed
              data={feedData}
              wideScreenSideAd={wideScreenSideAd}
              wideScreenSideAd2={wideScreenSideAd2}
              layoutType={feedLayoutType}
              endOfFeed={noMoreArticles}
              headerTitle={headerTitle || ""}
            />
          )}
        </>
      )}

      {noMoreArticles ? (
        <div className={styles.endOfFeed} />
      ) : (
        <>
          <div className={styles.loader}>{loading && <DotLoader />}</div>
          <div ref={ref} className={styles.feedLoadLimit}></div>
        </>
      )}
    </div>
  );
};

export { FeedLoader };
