import type { Article, Group } from "../../../types/content";

const omitRepeated = (
  prevGroup: Group[],
  nextGroupToAdd: Group[],
  initialItems: Group[],
) => {
  const allPreviousGroups = [...initialItems, ...prevGroup];

  const filteredArticles = nextGroupToAdd.filter((group) => {
    if (group?.[0] && group[0].type === "Box") {
      const boxName = group[0].name;

      //Filter out boxes if they already exist in previous groups list
      return !allPreviousGroups.some(
        (prevGroup) =>
          prevGroup[0] &&
          prevGroup[0].type === "Box" &&
          prevGroup[0]?.name === boxName,
      );
    } else if (group?.[0] && group[0].type === "Article") {
      const article = group[0] as Article;

      //Filter out articles if they already exist in previous groups list
      return !allPreviousGroups.some(
        (prevGroup) =>
          prevGroup?.[0] &&
          prevGroup[0].type === "Article" &&
          (prevGroup[0] as Article)?.article_id === article.article_id,
      );
    } else return true;
  });

  return [...prevGroup, ...filteredArticles];
};

export { omitRepeated };
