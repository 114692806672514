interface StoryVignetteSuffix {
  suffix?: string;
  className?: string;
}

const StoryVignetteSuffix: React.FC<StoryVignetteSuffix> = ({
  suffix,
  className,
}) => {
  if (!suffix) return null;

  return <span className={className}>{suffix}</span>;
};

export { StoryVignetteSuffix };
