import Link from "next/link";

import type { Topic } from "../../../../../types/content";

import { getSlugFromTitle } from "../../../../../utils/getSlugFromTitle";

interface StoryVignetteSupertagProps {
  supertag?: Topic;
  className?: string;
}

const StoryVignetteSupertag: React.FC<StoryVignetteSupertagProps> = ({
  supertag,
  className,
}) => {
  if (!supertag) return null;
  const slug = getSlugFromTitle(supertag.title);
  return supertag.topic_id ? (
    <Link
      href={`/t/${slug}/${supertag.topic_id}`}
      className={className}
      prefetch={false}
    >
      {supertag.title}
    </Link>
  ) : (
    <span className={className}>{supertag.title}</span>
  );
};

export { StoryVignetteSupertag };
