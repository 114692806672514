"use client";

import type { Article, Box, Group } from "../../../types/content";

import { mapTeaserStyle } from "../utils/mapTeaserStyle";
import { LatestNewsBoxCluster } from "./LatestNewsBoxCluster/LatestNewsBoxCluster";
import { LatestNewsArticleCluster } from "./LatestNewsArticleCluster/LatestNewsArticleCluster";
import { InfoCard } from "../../InfoCard/InfoCard";

const LatestNewsLayout = ({
  data,
  isFirstFeed,
}: {
  data: Group[];
  isFirstFeed?: boolean;
}) => {
  return (
    <div>
      {data.map((feedGroup, clusterIndex) => {
        if (!feedGroup?.[0]) return null;
        const teaserStyle = mapTeaserStyle(feedGroup);
        const isLastElement = data.length === clusterIndex + 1;
        const isFirstElement = clusterIndex === 0;
        const hasBoxAfter = data?.[clusterIndex + 1]?.[0]?.type === "Box";
        const hasBoxBefore = data?.[clusterIndex - 1]?.[0]?.type === "Box";
        const groupType = feedGroup?.[0]?.type;

        const articleElement =
          feedGroup[0]?.type === "Article" && (feedGroup[0] as Article);
        const isInfoCard =
          articleElement && articleElement?.teaser_layout === "Fishstick";

        if (groupType === "Box") {
          const boxGroup = feedGroup[0] as Box;
          const boxName = boxGroup.name;
          return (
            <LatestNewsBoxCluster
              boxGroup={boxGroup}
              teaserStyle={teaserStyle}
              boxName={boxName}
              key={`${boxGroup.name}${clusterIndex}`}
            />
          );
        }

        if (isInfoCard) {
          return (
            <InfoCard
              articleLink={{
                articleId: articleElement.article_id,
                slug: articleElement?.meta?.promotion_content?.slug || "",
              }}
              articleIsPremium={articleElement.style === "premium"}
              vignette={articleElement.story_vignette.title}
              title={articleElement.title.value}
              iconImageUrl={articleElement.story_vignette.icon_image?.url.light}
              key={`${articleElement.article_id} ${clusterIndex}`}
              viewType="latest"
            />
          );
        }

        if (groupType === "Article") {
          const shouldPrioritizeImageLoad = clusterIndex > 3 && isFirstFeed;
          return (
            <LatestNewsArticleCluster
              feedGroup={feedGroup}
              teaserStyle={teaserStyle}
              shouldPrioritizeImageLoad={shouldPrioritizeImageLoad}
              isLastElement={isLastElement || hasBoxAfter}
              isFirstElement={isFirstElement || hasBoxBefore}
              key={`${(feedGroup?.[0] as Article)?.article_id}${clusterIndex}`}
            />
          );
        }
      })}
    </div>
  );
};

export { LatestNewsLayout };
